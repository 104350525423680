<template>
  <section>
    <b-row>
      <b-col cols="12">
        <daas-list />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import DaasList from '@/views/DaasList.vue'

export default {
  components: {
    BRow,
    BCol,

    DaasList,
  },
}
</script>

<style>

</style>
