<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
      variant="dark"
    >
      <div>
        <b-card>
          <b-row>
            <b-col
              md="12"
              sm="12"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-daas-link
                variant="outline-primary"
                class="float-right"
              >
                <feather-icon
                  icon="ExternalLinkIcon"
                  size="16"
                  class="bg-light-info"
                />
                <span class="align-middle ml-50">Send DAAS Link</span>
              </b-button>

              <router-link to="/create-daas">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="float-right mr-2"
                >
                  <feather-icon
                    icon="BoxIcon"
                    size="16"
                    class="bg-light-info"
                  />
                  <span class="align-middle ml-50">Create DAAS</span>
                </b-button>
              </router-link>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="2"
              sm="4"
              class="my-1"
            >
              <b-form-group class="mb-0">
                <label
                  class="d-inline-block text-sm-left mr-50"
                >Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              sm="8"
              class="my-1"
            >
              <b-form-group
                label="Sort"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-75"
                  >
                    <template v-slot:first>
                      <option value="">
                        -- none --
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                    class="w-25"
                  >
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              class="my-1"
            >
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-table
                striped
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                small="small"
                @filtered="onFiltered"
              >
                <template #cell(contact)="data">
                  {{ data.item.params.name }}<br>
                  {{ data.item.params.phone }}<br>
                  {{ data.item.params.email }}
                </template>

                <!-- date -->
                <template #cell(date)="data">
                  <div class="d-flex flex-column">
                    <span class="mb-25">{{
                      new Date(data.item.created_at).toLocaleTimeString(
                        "en-us",
                        {
                          weekday: "long",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )
                    }}</span>
                    <span
                      class="font-small-2 text-muted text-nowrap"
                    >{{
                      <timeago :datetime="data.item.created_at" />
                      }}</span>
                  </div>
                </template>

                <!-- Link -->
                <template #cell(url)="data">
                  <b-tooltip
                    :target="'tooltip-' + data.item.id"
                    :title="`Click to copy`"
                  />
                  <span
                    :id="'tooltip-' + data.item.id"
                    @click="copy(data.item.url)"
                  >{{ data.item.url }}</span>
                </template>

                <!-- Actions -->
                <template #cell(actions)="data">
                  <div
                    class="text-nowrap"
                    variant="danger"
                  >
                    <b-avatar variant="light-secondary">
                      <feather-icon
                        :id="`dispatches-row-${data.item.id}-verify-icon`"
                        :icon="
                          data.item.status == 'LINK'
                            ? 'SendIcon'
                            : data.item.status == 'REQUEST' ||
                              data.item.status == 'PAYMENT'
                              ? 'CreditCardIcon'
                              : data.item.status == 'PAID' ||
                                data.item.status == 'DISPATCH'
                                ? 'TruckIcon'
                                : data.item.status == 'COLLECTED' ? 'TruckIcon' : 'CheckIcon'
                        "
                        class="cursor-pointer"
                        size="16"
                        @click="showDaasAction(data.item)"
                      />
                    </b-avatar>
                    <b-tooltip
                      :title="data.item.status == 'LINK'
                        ? 'Resend Link'
                        : data.item.status == 'REQUEST' ||
                          data.item.status == 'PAYMENT'
                          ? 'Payment'
                          : data.item.status == 'PAID' ||
                            data.item.status == 'DISPATCH'
                            ? 'Dispatch'
                            : 'Completed'"
                      class="cursor-pointer"
                      :target="`dispatches-row-${data.item.id}-verify-icon`"
                    />
                  </div>
                </template>

                <!-- Show details -->
                <template #cell(show_details)="data">
                  <b-button
                    size="sm"
                    class="mr-2"
                    @click="data.toggleDetails"
                  >
                    {{ data.detailsShowing ? 'Hide' : 'Show' }} Details
                  </b-button>

                  <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
                  <!-- <b-form-checkbox v-model="data.detailsShowing" @change="data.toggleDetails">
                    Details via check
                  </b-form-checkbox> -->
                </template>

                <!-- Dispatch -->
                <!-- <template #cell(dispatch)="data"> -->
                <template #row-details="data">
                  <hr>
                  <div class="d-flex flex-column">
                    <small>
                      <span
                        v-if="data.item.dispatch_object != null && data.item.dispatch_object.pickup !=null"
                        class="pb-2"
                      >
                        <b-row>
                          <b-col cols="1">
                            <feather-icon
                              icon="MapPinIcon"
                              class="text-danger"
                              title="Pickup"
                            />
                          </b-col>
                          <b-col cols="11">
                            <li>
                              {{
                                data.item.dispatch_object != null
                                  ? data.item.dispatch_object.pickup != null
                                    ? data.item.dispatch_object.pickup.address
                                    : ""
                                  : ""
                              }}
                            </li>
                          </b-col>
                        </b-row>

                      </span>
                      </br>
                      <span
                        v-if="data.item.dispatch_object != null && data.item.dispatch_object.dropoffs !=null"
                        class=""
                      >
                        <b-row>
                          <b-col cols="1">
                            <feather-icon
                              icon="MapPinIcon"
                              class="text-info"
                            />
                          </b-col>
                          <b-col cols="11">
                            <li
                              v-for="(drop) in data.item.dispatch_object.dropoffs"
                              :key="drop.index"
                            >
                              {{ drop.address }}
                              <h6>Packages</h6>
                              <ol>
                                <li
                                  v-for="(pack) in drop.packages"
                                  :key=""
                                  pack.index
                                >{{ pack.name }}</li>
                              </ol>
                              <h6>Recipient Details</h6>
                              <ul>
                                <li>Name: {{ drop.receiver_name }}</li>
                                <li>Phone: {{ drop.receiver_phone }}</li>
                              </ul>
                            </li>
                          </b-col>
                        </b-row>
                      </span>
                    </small>
                    <b-button
                      size="sm"
                      @click="data.toggleDetails"
                    >
                      Hide Details
                    </b-button>
                  </div>
                </template>

              </b-table>
            </b-col>

            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>

          <!--  DAAS LINK MODAL -->
          <b-modal
            id="modal-daas-link"
            ref="modal-daas-link"
            centered
            title="Send DAAS Link"
            ok-only
            ok-title="Send Link"
            hide-footer
          >
            <div>
              <b-overlay
                :show="showDaasLinkOverlay"
                rounded="sm"
                variant="dark"
              >
                <div>
                  <validation-observer ref="simpleRules">
                    <b-form
                      @submit="onSubmitDaasLink"
                      @reset="onResetDaasLink"
                    >
                      <b-form-group>
                        <label for="name">Name:</label>
                        <validation-provider
                          #default="{ errors }"
                          name="Name"
                          rules="required"
                        >
                          <b-form-input
                            id="name"
                            v-model="customer.name"
                            type="text"
                            placeholder="Name"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group>
                        <label for="email">Email(Optional):</label>
                        <validation-provider
                          #default="{ errors }"
                          name="Email"
                          rules="email"
                        >
                          <b-form-input
                            id="email"
                            v-model="customer.email"
                            type="email"
                            placeholder="Email Address"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-row>
                        <b-col>
                          <b-form-group>
                            <label for="phone">Phone:</label>
                            <validation-provider
                              #default="{ errors }"
                              name="Phone"
                              rules="required|integer|min:11|digits:11"
                            >
                              <b-form-input
                                id="phone"
                                v-model="customer.phone"
                                type="text"
                                placeholder="Phone (080XXXXXXXX)"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <hr>
                      <b-row>
                        <b-col
                          md="12"
                          lg="12"
                        >
                          <b-button
                            class="mx-1 float-right"
                            type="submit"
                            variant="primary"
                          >Send Link</b-button>
                          <b-button
                            class="mx-0 float-right"
                            type="reset"
                            variant="danger"
                          >Reset</b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </validation-observer>
                </div>
              </b-overlay>
            </div>
          </b-modal>

          <!-- DAAS ACTION MODAL -->
          <b-modal
            id="modal-daas-action"
            ref="modal-daas-action"
            centered
            title="DAAS Action"
            ok-only
            ok-title="Proceed"
            hide-footer
          >
            <div>
              <b-overlay
                :show="showDaasActionOverlay"
                rounded="sm"
                variant="dark"
              >
                <div>
                  <b-form
                    @submit="onSubmitDaasAction"
                    @reset="onResetDaasAction"
                  >
                    <b-form-group>
                      <label>Name:</label>
                      <b-form-input
                        plaintext
                        :value="daas.params.name"
                      />
                    </b-form-group>
                    <b-form-group>
                      <label>Phone:</label>
                      <b-form-input
                        plaintext
                        :value="daas.params.phone"
                      />
                    </b-form-group>
                    <b-form-group>
                      <label>Email:</label>
                      <b-form-input
                        plaintext
                        :value="daas.params.email"
                      />
                    </b-form-group>

                    <!--SET PAYMENTS-->
                    <b-form-group v-if="daas.status == 'REQUEST'">
                      <label for="amount">Set Amount:</label>
                      <b-form-input
                        id="amount"
                        v-model="daas.amount"
                        type="text"
                        placeholder="Amount"
                      />
                    </b-form-group>

                    <!--ASSIGN RIDER CREATE DISPATCH-->
                    <b-form-group v-if="daas.status == 'PAID'">
                      <label for="rider">Select Rider:</label>
                      <b-form-select
                        id="rider"
                        v-model="rider"
                        name="rider"
                        required
                      >
                        <option
                          v-for="rider in riders"
                          :id="rider.id"
                          :key="rider.id"
                          :value="rider"
                        >
                          {{ rider.name }} - {{ rider.plate_number }} [{{
                            rider.phone
                          }}]
                        </option>
                      </b-form-select>
                    </b-form-group>

                    <!--SET DISPATCH STATUS-->
                    <b-form-group
                      v-if="
                        daas.status == 'DISPATCH' || daas.status == 'COLLECTED'
                      "
                    >
                      <label for="status">Select Status:</label>
                      <b-form-select
                        id="status"
                        v-model="status"
                        name="status"
                        required
                      >
                        <option>COLLECTED</option>
                        <option>DELIVERED</option>
                      </b-form-select>
                    </b-form-group>

                    <!-- FORM BUTTONS -->
                    <hr>
                    <b-row>
                      <b-col
                        md="12"
                        lg="12"
                      >
                        <b-button
                          class="mx-1 float-right"
                          type="submit"
                          variant="primary"
                        >Proceed</b-button>
                        <b-button
                          class="mx-0 float-right"
                          type="reset"
                          variant="danger"
                        >Reset</b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
              </b-overlay>
            </div>
          </b-modal>
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BTable,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BTooltip,
  BModal,
  BForm,
  BOverlay,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import { apiUrl } from '@/constants/config'
import store from '@/store'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from '@validations'

import { mapGetters } from 'vuex'

export default {
  directives: {
    // "b-modal": VBModal,
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BTable,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BTooltip,
    BModal,
    BForm,
    BOverlay,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [5, 10, 25],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        // { key: "name", label: "Full Name", sortable: true },
        'contact',
        // { key: "created_at", label: "Date", sortable: true },
        { key: 'date', label: 'Date', sortable: true },
        // { key: "dispatch", label: "Dispatch", sortable: false },
        { key: 'url', label: 'Link', sortable: false },
        { key: 'status', label: 'status' },
        { key: 'actions', label: 'Action' },
        { key: 'show_details', label: 'Details' },
      ],
      customer: {
        name: '',
        email: '',
        phone: '',
      },
      items: [],
      show: false,
      showDaasLinkOverlay: false,
      showDaasActionOverlay: false,
      daas: {
        id: '',
        params: { name: '', email: '', phone: '' },
        created_at: '',
        link: '',
        status: '',
        amount: '',
      },
      rider: {},
      riders: [],
      status: '',
    }
  },
  setup() {
    return {
      avatarText,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    ...mapGetters(['daasEvent']),
  },
  watch: {
    daasEvent(val) {
      if (val && val == true) {
        this.getDaas()
      }
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  created() {
    this.getDaas()
    this.getRiders()
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getDaas() {
      this.show = true
      try {
        axios
          .get(`${apiUrl}daas`, {
            headers: {
              Authorization: `Bearer ${store.state.user.currentUser.token}`,
            },
          })
          .then(response => {
            store.state.user.daasEvent = false

            this.items = response.data.data.data
            // for (const item in this.items) {
            //   item._isShowing = true
            // }
            this.items.forEach(item => {
              // eslint-disable-next-line no-underscore-dangle, no-param-reassign
              item._isShowing = true
            })
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.show = false
          })
      } catch (e) {
        console.log(e)
      }
    },

    copy(text) {
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      textArea.remove()

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Link Copied',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },

    onSubmitDaasLink(event) {
      this.showDaasLinkOverlay = true
      event.preventDefault()
      /*  if (this.customer.name == "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error: Please Provide a customer name!",
            icon: "EditIcon",
            variant: "danger",
          },
        });
        this.showDaasLinkOverlay = false;
      } else */ /* if (this.customer.email == "" && this.customer.phone == "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error: Please provide a phone number or email!",
            icon: "EditIcon",
            variant: "danger",
          },
        });
        this.showDaasLinkOverlay = false;
      } else { */
      // create link, link hash
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // post data
          axios({
            url: `${apiUrl}daas`,
            data: this.customer,
            method: 'POST',
            headers: {
              'content-type': 'application/json',
              Authorization: `Bearer ${store.state.user.currentUser.token}`,
            },
          })
            .then(() => {
              this.getDaas()
              this.$refs['modal-daas-link'].hide()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success: DAAS link created and sent successfully!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Error: ${error.message}`,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.showDaasLinkOverlay = false
            })
        } else {
          this.showDaasLinkOverlay = false
        }
      })
      // }
    },

    onResetDaasLink(event) {
      event.preventDefault()
      this.customer.name = ''
      this.customer.email = ''
      this.customer.phone = ''
    },
    onSubmitDaasAction(event) {
      this.showDaasActionOverlay = true
      event.preventDefault()
      let data = {}
      if (this.daas.status == 'REQUEST') data = { amount: this.daas.amount, status: 'PAYMENT' }
      else if (this.daas.status == 'PAID') {
        data = { rider_id: this.rider.id, status: 'DISPATCH' } // update dispatch
      } else {
        data = { status: this.status } // update status
      }

      // post data
      axios({
        url: `${apiUrl}daas/${this.daas.id}`, // this.$route.params.ref
        data,
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${store.state.user.currentUser.token}`,
        },
      })
        .then(response => {
          this.getDaas()
          this.$refs['modal-daas-action'].hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Success: ${response.data.message}`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                `Error: ${
                  error.response.statusText
                }. ${
                  error.response.data.message}` ?? error.message,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.showDaasActionOverlay = false
        })
    },
    onResetDaasAction() {},
    showDaasAction(item) {
      this.$refs['modal-daas-action'].show()
      // console.log(this.daas);
      this.daas = item
      this.daas.params = item.params
    },
    getRiders() {
      this.show = true
      try {
        axios
          .get(`${apiUrl}rider`, {
            headers: {
              Authorization: `Bearer ${store.state.user.currentUser.token}`,
            },
          })
          .then(response => {
            // console.log(response.data.data);
            this.riders = response.data.data
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.show = false
          })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
